import axios from "axios";
import { getToken } from "./auth";

// export const baseURL = "http://127.0.0.1:8000";
// export const baseURL = "http://localhost/Palma_Quote_Laravel";
// export const baseURL = "https://api.palmaseguros.tech";
  export const baseURL = "https://apidum.palmaseguros.tech";

const API = axios.create({
  baseURL: baseURL + "/api",
});

API.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { API };
